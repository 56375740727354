// eslint-disable-next-line no-unused-vars
import {useEffect, useState} from 'react';
import axios from 'axios';
import ApiUtils from '../utils/apiUtils';

export const useGetFilterList = (props) => {

  const [options, setOptions] = useState(null);

  const fetchOptions = async () => {
    try{
      //console.log('inside fetch options');

      const params  = props?.params ?? {};

      //console.log('use get filter list params');
      //console.log(params);

      const authToken =  await ApiUtils.getAccessToken();
      const results = await axios.get(props.url, {params: params, headers: {Authorization: authToken}});

      //console.log('get tags from server');
      //console.log(results.data);

      return results.data.data; 
 
    } catch (e) {
      console.error(e);
      return null;
    }

  };

  useEffect(async () => {
    const options = await fetchOptions();
    setOptions(options);
  }, []);

  return {
    options: options
  };

};
