const JOB_TYPES = {
  HVAC_RES: {api_name: 'hvac_res', display: 'Mechanical (HVAC)', value: 'Mechanical (HVAC)', showOrder: 0, def: false},
  ELECTRICAL: {api_name: 'electrical', display: 'Electrical', value: 'Electrical', showOrder: 3, def: false},
  SOLAR: {api_name: 'solar', display: 'Solar', value: 'Solar', showOrder: 7, def: false},
  PLUMBING: {api_name: 'plumbing', display: 'Plumbing', value: 'Plumbing', showOrder: 4, def: false},
  REROOF: {api_name: 'reroof', display: 'Reroof', value: 'Reroof', showOrder: 5, def: false},
  MISC: {api_name: 'misc', display: 'Misc', value: 'Misc', showOrder: 8, def: false},
  BUSINESS_LIC: {api_name: 'business_lic', display: 'Business License', value: 'Business License', showOrder: 6, def: false},
  TESTING_ONLY: {api_name: 'testing_only', display: 'HERS Test Only', value: 'Testing Only', showOrder: 1, def: false},
  TEMPORARY_POWER: {api_name: 'temporary_power', display: 'Temporary Power', value: 'Temporary Power', showOrder: 2, def: false},
  COMMERCIAL: {api_name: 'commercial', crm_name: 'hvac_com', display: 'commercial', value: 'commercial', showOrder: -1, def: false},
  INSPECTION_ONLY: {api_name: 'inspection_only', display: 'Inspection Only', value: 'Inspection Only', showOrder: 9, def: false},
  WINDOW: {api_name: 'window', display: 'Window', value: 'Window', showOrder: 10, def: false},  
  DOOR: {api_name: 'door', display: 'Door', value: 'Door', showOrder: 11, def: false},
  REINSPECTION: {api_name: 're-inspection', display: 'Re-inspection', value: 'Re-inspection', showOrder: 12, def: false},
};

const ALL_JOBS_TYPE = {api_name: 'all', display: 'All Jobs', value: 'All Jobs', showOrder: 0, def: false};

const MAP_JOB_TYPE_API_TO_NAME = {};
const MAP_JOB_TYPE_VALUE_TO_API = {};
// eslint-disable-next-line no-unused-vars
for (const [key, value] of Object.entries(JOB_TYPES)) {
  MAP_JOB_TYPE_VALUE_TO_API[value.value] = value.api_name;
  MAP_JOB_TYPE_API_TO_NAME[value.api_name]=value.value;
}

const TEST_TYPES = {
  NONE: {display: 'None', value: 'None', showOrder: 0, def: true},
  HERS: {display: 'HERS', value: 'HERS', showOrder: 1, def: false},
  EUC: {display: 'EUC', value: 'EUC', showOrder: 2, def: false},
};

export {JOB_TYPES, TEST_TYPES, MAP_JOB_TYPE_API_TO_NAME, MAP_JOB_TYPE_VALUE_TO_API, ALL_JOBS_TYPE};
