/* eslint-disable */
import React, {useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import * as allConstants from '../../../../constants/allConstants';
import {RadioGroup, Radio, FormControl, FormLabel, Typography, Paper, Box} from '@mui/material';
import JobTestingImg from '../../../../static/images/job_test_only.png';
import JobHvacImg from '../../../../static/images/job_hvac.png';
import JobSolarImg from '../../../../static/images/job_solar.png';
import JobReroofImg from '../../../../static/images/job_reroof.png';
import JobBussnesLicImg from '../../../../static/images/job_business_lic.png';
import JobPlumbingImg from '../../../../static/images/job_plumbing.png';
import JobElectricalImg from '../../../../static/images/job_electrical.png';
import JobTempPowerImg from '../../../../static/images/job_temp_power.png';
import JobMiscImg from '../../../../static/images/job_misc.png';
import * as STYLE_CONSTANTS from '../../../../styles/styleConstants';
import {useFormContext} from 'react-hook-form';

const ImageRadio = (props)=> {

  return(
    <Radio checked={props.selected === props.value}
      value={props.value}
      checkedIcon={props.icon}
      icon={props.icon}
           disabled={props.iconDisabled}
      sx={{
        borderRadius: 0,
        padding: 0,
        margin: 2,
        '&.MuiRadio-root.Mui-disabled': {
          cursor: 'not-allowed',
          pointerEvents: 'initial'
        },
        '&:hover': {
          backgroundColor: 'unset',

          '& .MuiPaper-root': {
            backgroundColor: props.iconDisabled ? 'initial' : STYLE_CONSTANTS.COLORS.globalBlue,
            '& img, & .MuiTypography-root': {
              filter: props.iconDisabled ? 'none' : 'brightness(0) invert(1)',
            }
          }
        },
        '&.Mui-checked': {
          '& .MuiPaper-root': {
            filter: 'none',
            opacity: 1,
            backgroundColor: `${STYLE_CONSTANTS.COLORS.globalBlue} !important`,
            '& img, & .MuiTypography-root': {
              filter: 'brightness(0) invert(1)',
            }
          }
        }

      }}
    />

  );

};



const SelectJobType = (props)=> {
  const {JOB_TYPES} = allConstants;
  const formController = useFormContext()
  const jobMap = {
    [JOB_TYPES.HVAC_RES.value]: JobHvacImg,
    [JOB_TYPES.TESTING_ONLY.value]: JobTestingImg,
    [JOB_TYPES.REROOF.value]: JobReroofImg,
    [JOB_TYPES.SOLAR.value]: JobSolarImg,
    [JOB_TYPES.MISC.value]: JobMiscImg,
    [JOB_TYPES.BUSINESS_LIC.value]: JobBussnesLicImg,
    [JOB_TYPES.TEMPORARY_POWER.value]: JobTempPowerImg,
    [JOB_TYPES.PLUMBING.value]: JobPlumbingImg,
    [JOB_TYPES.ELECTRICAL.value]: JobElectricalImg,
    [JOB_TYPES.INSPECTION_ONLY.value]: JobMiscImg,
    [JOB_TYPES.WINDOW.value]: JobMiscImg,
    [JOB_TYPES.DOOR.value]: JobMiscImg,
    [JOB_TYPES.REINSPECTION.value]: JobMiscImg,
  };

  const createMenuItem = (job, iconDisabled)=> {
    const extraStyles = iconDisabled ? {
      filter: props.isDraft ? 'grayscale(1)' : 'none',
      opacity: '0.5',
    } :{}
    return(
      <Paper elevation={0} sx={{
        width: {xl: 150, xs: 130},
        height: {xl: 150, xs: 130},
        paddingTop: {xs: 1, xl:2},
        paddingX: '6px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-around',
        textAlign: 'center',
        boxShadow: STYLE_CONSTANTS.DEFAULT.BOX_SHADOW,
        ...extraStyles,
        '& img': {
          transform: {xl: 'scale(0.8)', xs: 'scale(0.7)'}
        }
      }}>
        <img src={jobMap[job.value]} alt={job.name}/>
        <Typography sx={{
          fontSize: {
            md: 18,
            xs: 16
          },
          color: '#000'
        }}> {job.name}</Typography>

      </Paper>
    );
  };

  useEffect(()=> {
    if(props.allowedJobs?.length === 1) {
      formController.setValue(allConstants.JOB_FIELDS.JOB_TYPE.api_name, props.allowedJobs[0].value, {shouldValidate: true, shouldTouch: true, shouldDirty: true})
    }
  }, [])


  const selected = props.allowedJobs?.length === 1 ? props.allowedJobs[0].value : props.selected;
  const jobList = props.allowedJobs.map((job, index)=>

  {

    return jobMap[job.value] ?
    <ImageRadio key={`${index}-${job.value}`} icon={createMenuItem(job, props.isDraft)}
                value={job.value} selected={selected}
                iconDisabled={props.isDraft}
    />
    : null;});

  return <Box display={'flex'} flexWrap={'wrap'} justifyContent={'center'}>
    {jobList}
  </Box>

};

SelectJobType.propTypes = {
  allowedJobs: PropTypes.instanceOf(Array)
};

SelectJobType.defaultProps = {
  allowedJobs: [],

};

export default SelectJobType;
