import React from 'react';
import {useFormContext, useFieldArray} from 'react-hook-form';
import {useCompanyPageStyles} from '../companyPageStyles';
import {globalUseClasses} from '../../../styles/globalClasses';
import * as allConstants from '../../../constants/allConstants';
import * as STYLE_CONSTANTS from '../../../styles/styleConstants';
import Grid from '@mui/material/Grid';
import {Box, Card, CardHeader, CardContent, List, Typography, ListItem, Button, Checkbox, 
  Table, TableBody, TableCell, TableRow, TableHead} from '@mui/material';
import CustomFormBlock from '../../../pieces/inputs/formBlock';
import {inputTypes} from '../../../pieces/inputs/formInput';
import Utils from '../../../utils/utils';
//import {AddRowButton, DeleteEquipmentRowButton,variants} from '../../../pieces/addDeleteEquipmentRowButtons';
//import base_url from '../../../baseUrls';
import {companyPropTypes, companyDefaultProps} from './panelProps';
import {NotAllowedToEditSection} from './notAllowedToEditSection';
import {NoDataStringWrapper} from '../../../pieces/noDataStringWrapper';
import TagImage from '../../../static/images/tag-green.png';

import {useNavigate} from 'react-router-dom';

export const ServiceTitanInfo  = (props)=> {

  //console.log('Servic Titan Info');
  //console.log(props);

  const navigate = useNavigate();

  const classes = {...useCompanyPageStyles(), ...globalUseClasses()};
  const COMPANY_FIELDS = allConstants.COMPANY_FIELDS;
  //  const EMP_FIELDS = allConstants.SERVICE_TITAN_EMP_FIELDS;
  const formMethods = useFormContext();

  const getCompanyData = ()=> {
    const initialData = props.tabProps?.companyData || {};
    return {...initialData, ...formMethods.getValues()};
  };
  
  const companyData = getCompanyData();

  const getDefaultValue = (field, parent = null) => {
    if (parent) {
      return companyData?.[parent]?.[field];
    }
    return companyData?.[field];
  };

  const commonFieldProps = {
    fullWidth: true,
    maxWidth: '100%',
    nested: true,
    parent: COMPANY_FIELDS.SERVICE_TITAN.api_name
  };

  const empParent = COMPANY_FIELDS.SERVICE_TITAN.api_name;
  const empCollectionName = COMPANY_FIELDS.TENANT_EMP_LIST.api_name;

  const {fields} = useFieldArray({
    control: formMethods.control,
    name: `${empParent}.${empCollectionName}`
  });

  const empSubformDefaultProps = (index) => ({
    defaultValue: '',
    isEditable: false,
    nested: true,
    fullWidth: true,
    parent: `${empParent}.${empCollectionName}[${index}]`
  });


  // const editUser = async (permitUserId) => {
  //   const companyId = Utils.getUrlParameter('companyId') ?? '';
    
  //   let url = `${allConstants.PATH.USER}?companyId=${companyId}&userId=${permitUserId}`;
  //   url = `${url}&view=${allConstants.PATH.COMPANY}&tabName=serviceTitanInfo`;
  //   navigate(url);

  // }

  // const createUser = async (email, name) => {
  //   const list = name?.split(" ",2);
  //   const firstName = list.length > 0 ? list[0].trim() : "";
  //   const lastName = list.length > 1 ? list[1].trim() : "";
  //   const companyId = Utils.getUrlParameter('companyId') ?? '';

  //   //console.log('location=>');
  //   //console.log(location.search);

  //   let url = `${allConstants.PATH.USER}?companyId=${companyId}&email=${email}&firstName=${firstName}&lastName=${lastName}`;
  //   url = `${url}&view=${allConstants.PATH.COMPANY}&tabName=serviceTitanInfo`;
   
  //   //console.log('firstName => ' + firstName);
  //   //console.log('lastName => ' + lastName);
  //   //console.log('url => ' + url);

  //   navigate(url);
  // }

  // const getTagContent = (rows)  => {
  //   if(!rows || rows.length === 0) {
  //     return <TableRow>
  //       <TableCell sx={{width: '100%'}} colSpan={1}>
  //         <NoDataStringWrapper text={`No Tag Names`}/>
  //       </TableCell>
  //     </TableRow>;
  //   }
  //   return (
  //     <>
  //       {rows.map((row) => (       
  //         <TableRow key={row} sx={{'&:last-child td, &:last-child th': {border: 0}, '& .MuiTableCell-root': {paddingY: '4px'}}}>
  //           <TableCell component="th" scope="row" sx={{paddingLeft: '8px'}} >
  //             {row}              
  //           </TableCell>
  //         </TableRow>           
  //       )}
  //     </>
  //   );
  // }

  const getTagNameContent =  (items) => {
    //console.log('tags =>');
    //console.log(items);

    if (items == undefined) {
      return;
    }
    return (
      <>
      <List>
        {items.map ((item, idx) => (
          <ListItem key={item + idx} sx={{padding: '0'}}>
            <img src={TagImage}  width="25px" height="25px"  />
            &nbsp;&nbsp;{item}
          </ListItem>
        ))}
      </List>
      </>
    )
  }
  // const getEmployeeContent = (rows)=> {

  //   if(!rows || rows.length === 0) {
  //     return <TableRow>
  //       <TableCell sx={{width: '100%'}} colSpan={6}>
  //         <NoDataStringWrapper text={`No Records`}/>
  //       </TableCell>
  //     </TableRow>;
  //   }

  //   return (
  //     <>
  //       {rows.map((row) => (       
  //         <TableRow key={row.email} sx={{'&:last-child td, &:last-child th': {border: 0}, '& .MuiTableCell-root': {paddingY: '4px'}}}>
  //           <TableCell component="th" scope="row" sx={{paddingLeft: '8px'}} >
  //             {row.name}              
  //           </TableCell>
  //           <TableCell component="th" scope="row" >
  //             {row.email}
  //           </TableCell>
  //           <TableCell coponent="th" scope="row">
  //             <Checkbox  checked={row.permitUser}/>
  //           </TableCell>
  //           <TableCell coponent="th" scope="row">
  //             <Checkbox  checked={row.isBlocked}/>
  //           </TableCell>
  //           <TableCell>
  //             {row.status}
  //           </TableCell>
  //           <TableCell>
  //             {row.permitUser ?
  //               <Button variant="contained" onClick={() => editUser(row.permitUserId)}>
  //                 EDIT
  //               </Button>
  //               :
  //               <Button variant="contained" onClick={() => createUser(row.email, row.name)}>
  //                 CREATE
  //               </Button>
  //             }
  //           </TableCell>
  //         </TableRow>
  //         )
  //       )}
  //     </>
  //   );
  // };
  // Thanh 01/04/24
  //console.log('employee data');
  //console.log(companyData);

  return (
    <>
      <Grid container width={'100%'} spacing={3}>
        <NotAllowedToEditSection show={props.tabProps.notAllowedToEdit}/>
        <Grid item sm={12} md={6} flex={1}>
          <Card className={classes.card_block}>
            <CardContent className={classes.half_width}>
                <CustomFormBlock
                  {...props.inputDefaultProps}
                  {...commonFieldProps}
                  field={COMPANY_FIELDS.TENANT_ID}
                  defaultValue={getDefaultValue(COMPANY_FIELDS.TENANT_ID.api_name, commonFieldProps.parent)}
                />
                <CustomFormBlock
                  {...props.inputDefaultProps}
                  {...commonFieldProps}
                  field={COMPANY_FIELDS.BUSINESS_UNIT_ID}
                  defaultValue={getDefaultValue(COMPANY_FIELDS.BUSINESS_UNIT_ID.api_name, commonFieldProps.parent)}
                />
                <CustomFormBlock
                  {...props.inputDefaultProps}
                  {...commonFieldProps}
                  field={COMPANY_FIELDS.BUSINESS_UNIT_NAME}
                  defaultValue={getDefaultValue(COMPANY_FIELDS.BUSINESS_UNIT_NAME.api_name, commonFieldProps.parent)}
                />
                <CustomFormBlock
                  {...props.inputDefaultProps}
                  {...commonFieldProps}
                  field={COMPANY_FIELDS.TENANT_CLIENT_ID}
                  defaultValue={getDefaultValue(COMPANY_FIELDS.TENANT_CLIENT_ID.api_name, commonFieldProps.parent)}
                />
              <CustomFormBlock
                  {...props.inputDefaultProps}
                  {...commonFieldProps}
                  field={COMPANY_FIELDS.TENANT_CLIENT_SECRET}
                  defaultValue={getDefaultValue(COMPANY_FIELDS.TENANT_CLIENT_SECRET.api_name, commonFieldProps.parent)}
                />
              <CustomFormBlock
                  {...props.inputDefaultProps}
                  {...commonFieldProps}
                  field={COMPANY_FIELDS.DEFAULT_CREATE_JOB_EMAIL}
                  defaultValue={getDefaultValue(COMPANY_FIELDS.DEFAULT_CREATE_JOB_EMAIL.api_name, commonFieldProps.parent)}
                />
              <Box className={classes.two_col_container} sx={{
                '& .MuiSelect-multiple': {
                  minWidth: 'unset !important'
                }
              }}>
                <CustomFormBlock
                  {...props.inputDefaultProps}
                  {...commonFieldProps}
                  renderLabel={false}
                  labelLeft={true}
                  inputType={inputTypes.checkbox}
                  field={COMPANY_FIELDS.ACTIVE_TENANT}
                  defaultValue={getDefaultValue(COMPANY_FIELDS.ACTIVE_TENANT.api_name, commonFieldProps.parent)}
                />
                <CustomFormBlock
                  {...props.inputDefaultProps}
                  {...commonFieldProps}
                  renderLabel={false}
                  labelLeft={true}
                  inputType={inputTypes.checkbox}
                  field={COMPANY_FIELDS.IS_PROD}
                  defaultValue={getDefaultValue(COMPANY_FIELDS.IS_PROD.api_name, commonFieldProps.parent)}
                />
              </Box>
              <Box className={classes.two_col_container} sx={{
                '& .MuiSelect-multiple': {
                  minWidth: 'unset !important'
                }
              }}>
                <CustomFormBlock
                  {...props.inputDefaultProps}
                  {...commonFieldProps}
                  renderLabel={false}
                  labelLeft={true}
                  inputType={inputTypes.checkbox}
                  field={COMPANY_FIELDS.USER_INTEGRATION}
                  defaultValue={getDefaultValue(COMPANY_FIELDS.USER_INTEGRATION.api_name, commonFieldProps.parent)}
                />
              </Box>
              <Button variant="contained" onClick={() => props.getServiceTitanData()}>
                  GET SERVICE TITAN DATA
                </Button>
            </CardContent>
          </Card>
        </Grid>
        <Grid item sm={12} md={6} flex={1}>
          <Card className={classes.card_block}>
              {/* <Typography className={classes.eq_name}>Service Titan Tag Names</Typography> */}
            <CardContent className={classes.half_width}>
              <Box>
                <Typography component="div" variant="h5">
                  Service Titan Tag Names
                </Typography>
                <hr/>
                {getTagNameContent(companyData.serviceTitan?.tagNames)} 
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

ServiceTitanInfo.propTypes = companyPropTypes;
ServiceTitanInfo.defaultProps = companyDefaultProps;
