import React, {useEffect, useState} from 'react';
import {Box, ThemeProvider} from '@mui/material';
import NavBar from '../../pieces/navbar/navbar';
import * as allConstants from '../../constants/allConstants';
import myTheme from '../../styles/myStyles';
import DisplayListsPageWrapper from '../wrappers/displayListsPageWrapper';
import AllCompaniesGrid from './allCompaniesGrid';
import {useNavigate} from 'react-router-dom';
import ListPageHeader from '../../pieces/listPageHeader';
import {useGridViewState} from '../../hooks/useGridViewState';
import usePagination from '../../hooks/usePagination';
import {globalUseClasses} from '../../styles/globalClasses';
import {NewPermissionGate} from '../../permissions/permissionGate';
import {COMPANY_ELEMENTS, companyRules} from '../../permissions/companyAccessControl';
import {urlSearchParams} from '../../baseUrls';
import ModalWrapper from '../../pieces/modals/modalWrapper';
import {EditFinancialForm} from './panels/editFinancialForm';

const AllCompaniesView = () => {
  const viewController = useGridViewState();
  const navigate = useNavigate();
  const pagination = usePagination();
  const classes = globalUseClasses();
  const [showEditFinancial, setShowEditFinancial] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(undefined);

  //console.log('inside all company view');


  useEffect(()=> {
    /**
     * This part is handling only following case: on companies view, select company and inpersonate it => regirect to company view page.
     * redirect on logout would be handled by App routes.
     */
    if(!viewController.user.id) return;
    const userCanViewPage = NewPermissionGate({
      user: viewController.user,
      elementPermissions: companyRules[COMPANY_ELEMENTS.allCompaniesNavLink],
    });
   
    //console.log('view controller properties');
    //console.log(viewController);

    if(!userCanViewPage && !viewController.isParentCompany) {

      //console.log('redirect to detail');

      if(viewController.companyId) {
        navigate(`${allConstants.PATH.COMPANY}?${urlSearchParams.companyId}=${viewController.companyId}`);
      } else {
        navigate(allConstants.PATH.ACCESS_DENIED);
      }

    }
  }, [viewController.companyId]);

  const headerComponent = <ListPageHeader header={'All Companies'}
    addHandler={()=>{navigate(`${allConstants.PATH.REGISTER_COMPANY}?${urlSearchParams.invite}=true`);}}
    showAddButton = {NewPermissionGate({
      user: viewController.user,
      elementPermissions: companyRules.inviteButton,
    })}

    totalItems={pagination.totalItemsCount}
  >
    {pagination.ItemsController}
  </ListPageHeader>;

  const editFinancialHandler = (companyData) => {
    setSelectedCompany(companyData);
    setShowEditFinancial(true);
  };

  const cancelEditFinancialHandler = () => {
    setSelectedCompany(undefined);
    setShowEditFinancial(false);
  };

  return (
    <>
      <NavBar/>
      <ThemeProvider theme={myTheme}>
        <Box className={classes.page}>
          {selectedCompany && showEditFinancial && <ModalWrapper
            handleClose={cancelEditFinancialHandler}
            open={true}
            modalName={'Edit Financial'}
            preventScroll={false}
          >
            <EditFinancialForm id={selectedCompany?.[allConstants.COMPANY_FIELDS.ID.api_name]} handleClose={cancelEditFinancialHandler}/>
          </ModalWrapper>}
          <DisplayListsPageWrapper
            headerComponent={headerComponent}>
            <AllCompaniesGrid viewController={viewController}
              editFinancial={editFinancialHandler}
              pagination={pagination}/>
            {pagination.PageController}
          </DisplayListsPageWrapper>
        </Box>
      </ThemeProvider>
    </>
  );
};
export default AllCompaniesView;
