const USER_FIELDS = {
  ID: {api_name: 'id', required: false, label: 'id', display_label: 'id'},
  NAME: {api_name: 'userName', required: false, label: 'User Name', display_label: 'User Name'},
  FIRST_NAME: {api_name: 'firstName', required: true, label: 'First Name', display_label: 'First Name'},
  LAST_NAME: {api_name: 'lastName', required: true, label: 'Last Name', display_label: 'Last Name'},
  EMAIL: {api_name: 'email', required: false, label: 'Email', display_label: 'Email'},
  ST_CREATE_JOB_EMAIL: {api_name: 'stCreateJobEmail', required: false, label: 'Alias', display_label: 'Alias'},
  ST_CREATE_JOB_USER: {api_name: 'stCreateJobUser', required: false, label: 'Alias', display_label: 'Alias'},
  ROLE: {api_name: 'role', required: false, label: 'Company Role', display_label: 'Company Role'},
  TITLE: {api_name: 'title', required: false, label: 'Title', display_label: 'Title'},
  PHONE_NUMBER: {api_name: 'phoneNumber', required: false, label: 'Phone No.', display_label: 'Phone No.'},
  COMPANY_ID: {api_name: 'companyId', required: false, label: 'Company', display_label: 'Company'},
  STATUS: {api_name: 'status', required: false, label: 'status', display_label: 'status'},
  IS_BLOCKED: {api_name: 'isBlocked', required: false, label: 'is blocked', display_label: 'is blocked'},
  COMPANY_NAME: {api_name: 'companyName', required: false, label: 'Company', display_label: 'Company'},
  PASSWORD: {api_name: 'password', required: true, label: 'Password', display_label: 'Password'},
  CONFIRM_PASSWORD: {api_name: 'currentPassword', required: true, label: 'Confirm Password', display_label: 'Password'},
  CHEERS_USERNAME: {api_name: 'username', required: true, label: 'Cheers Username', display_label: 'Cheers Username'},
  CHEERS_PASSWORD: {api_name: 'currentPassword', required: true, label: 'Cheers Password', display_label: 'Cheers Password'},
  CONNECT_TO_CHEERS: {api_name: 'connectToCheers', required: false, label: 'Require cheers connection', display_label: 'Require cheers connection'},
  IS_CONNECT_TO_CHEERS_REQUESTED: {api_name: 'isConnectToCheersRequested', required: false, label: 'Require cheers connection', display_label: ''},
  HAS_CONNECTED_TO_CHEERS: {api_name: 'hasConnectedToCheers', required: false, label: 'hasConnectedToCheers', display_label: ''},
  LOCATION: {api_name: 'location', required: false, label: 'Market', display_label: 'Market'},
  USER_COMPANIES: {api_name: 'userCompanies', required: false, label: 'Available Companies', display_label: 'Available Companies'},
};

export {USER_FIELDS};
