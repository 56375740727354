import React, {useState, useCallback} from 'react';
import {useSelector} from 'react-redux';
import {useGetRole} from './useGetRole';
import {useGetMyCompanyId} from './useGetMyCompanyId';
import usePagination from './usePagination';
import * as allConstants from '../constants/allConstants';
import BlockedPage from '../pages/misc/blockedCompany';
import AccessDenied from '../pages/misc/accessDenied';
import 'ag-grid-enterprise';
import axios from 'axios';
import ApiUtils from '../utils/apiUtils';
import Utils from '../utils/utils';

const useGridViewState = ()=> {

  //console.log('inside useGridViewState');

  const user = useSelector((state=> state.user));
  const company = useSelector((state=> state.company));

  //console.log('state - ompany =>>>>');
  //console.log(company);
  
  const role = useGetRole();
  const companyId = useGetMyCompanyId();
  const [fetching, setFetching] = useState(true);
  const [grid, setGrid] = useState();
  const [tempGrid, setTempGrid] = useState();  // Thanh 05/01/24
  const [fetchParams, setFetchParams] = useState(null); // Thanh 05/01/24
  const [currUrlSearchParams, ] = useState(null);
  //const [rowData, setRowData] = useState([]); // Thanh 05/01/24
  const [blocked, setBlocked] = useState(false);
  const [accessDenied, setAccessDenied] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const pagination = usePagination();
  const onFetchFail = (error) => {
    setFetching(false);
    if(error?.response?.data?.errorCode === allConstants.ERROR_CODES.COMPANY_BLOCKED) {
      setBlocked(true);
    }
  };
  const setTempGridToParent = (data, inFetchParams) => {
    setTempGrid(data);
    setFetchParams(inFetchParams);
    //setIsLoading(true);
  };

  const setGridToParent = (data)=> {
    setGrid(data);

    data.api.setSideBar({
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
    });

  };

  const [refreshTimestamp, setRefreshTimestamp] = useState(Date.now());
  /**
   *
   * @param url
   * @param respKey
   * @return {{respKey, url}}
   */
  const createFetchParams = (url, respKey)=> {
    return{
      url: url,
      respKey: respKey,
    };
  };

  const selectedMultiple = ()=> {
    if(!grid) return false;

    // Thanh 02/02/24
    if (grid.api.destroyCalled) {
     // console.log('it is already destroyed');
      return false;
    }
    const selectedNodes = grid.api.getSelectedNodes();
    return selectedNodes && selectedNodes.length >= 2;
  };

  const getSelected = ()=> {
    if(!grid) return null;

    // Thanh 02/02/24
    if (grid.api.destroyCalled) {
     // console.log('it is already destroyed');
      return false;
    }

    return grid.api.getSelectedNodes();
  };

  let RenderElement = (children)=><>{children}</>;
  if(blocked) {
    // eslint-disable-next-line react/display-name
    RenderElement = ()=><BlockedPage/>;
  } else if(accessDenied) {
    // eslint-disable-next-line react/display-name
    RenderElement = ()=><AccessDenied/>;
  }


  const exportAllAction = ()=> {

    //grid.api.exportDataAsExcel('filteredAndSorted');

    const api = grid.api;
    const currentPage = api.paginationGetCurrentPage();
    const pageSize = api.paginationGetPageSize();
    const startRow = currentPage * pageSize;
    const endRow = startRow + pageSize;
   
    const currentPageData = [];
    for (let i = startRow; i < endRow; i++) {
      const node = api.getDisplayedRowAtIndex(i);
      if (node) {
        currentPageData.push(node.data);
      }
    }
    tempGrid.api.setRowData(currentPageData);
    setTimeout(() => {
      tempGrid.api.exportDataAsExcel({ fileName: 'current_page_data.xlsx' });
      tempGrid.api.setRowData(null);
    }, 0);

  };

  const exportChecked = () => {
    grid.api.exportDataAsExcel({
      onlySelected: true,
    });
  };

  const formatFilterModel = (model) => {
        
    if (model === undefined) return {};

    for(const key of Object.keys(model)) {
      if (key === "cityInspection.requirement") {
        if (model["cityInspection.requirement"]["cityInspection.needsLadder"]) {
          model["cityInspection.needsLadder"] = model["cityInspection.requirement"]["cityInspection.needsLadder"];
        }
        model["cityInspection.requirement"] = model["cityInspection.requirement"]["cityInspection.requirement"];
      }
    }

    //console.log('new model');
    //console.log(model);

    for(const value of Object.values(model)) {
      if(value.dateFrom) value.dateFrom = Utils.formatLocalTimeToIsoString(value.dateFrom, true);
      if(value.dateTo) value.dateTo = Utils.formatLocalTimeToIsoString(value.dateTo, true);
    }

    return model;
  };

  
  const portalFiltersToAgGridModel = ()=> {
    const agGridFilters = {};
    // if(!additionalFilters.current) return agGridFilters;

    // for (const [field, filter] of Object.entries(additionalFilters.current)) {
    //   if(filter.param === 'empty' || filter.param === 'notEmpty') {
    //     agGridFilters[field] = {
    //       type: filter.param,
    //       filterType: filter.type
    //     };
    //   } else if(filter.value && filter.value.length !== 0){
    //     agGridFilters[field] = {
    //       type: filter.param,
    //       filterType: filter.type,
    //       filter: filter.value
    //     };
    //   }

    // }
    return agGridFilters;

  };

  const getServerSideDataSource =  async(page, limit) => {
    //const limit = 200;
    const api = fetchParams.url;
    const options = fetchParams.options;

    //console.log('inside get service side data source');
    //console.log(fetchParams);

    let filterModel = grid.api.getFilterModel();

    //console.log('main grid info');
    //console.log(grid);
    //console.log('main grid filter model');
    //console.log(filterModel);

    //const page = 1;
    //const page = Math.ceil(params.request.endRow / limit);
    filterModel = {...formatFilterModel(filterModel),...portalFiltersToAgGridModel()};

    // Thanh 02/024/24 
    //console.log('filter model #2');
    //console.log(filterModel);
    //console.log('after filter modal company id =>' + companyId);

    let requestParams = {limit: limit, page: page, filterModel: filterModel, sortModel: grid.api.request?.sortModel};
    if(options?.status) {
      requestParams = {...requestParams, status: options.status};
    }
    if(options?.tenantId) {
      requestParams = {...requestParams, tenantId: options.tenantId} 
    }
    if(options?.selectedDate) {
      requestParams = {...requestParams, selectedDate: options.selectedDate} 
    }
    
    if(companyId) {
      requestParams = {...requestParams, companyId: companyId};
    }

    let url = `${api}`;

    //console.log('url 2 => ' + url);
    //console.log('request param');
    //console.log(requestParams);
    // console.log(`start gettng userlist => ${new Date()}`);

    const authToken = await ApiUtils.getAccessToken();
    const userList = await axios.get(url, {params: requestParams, headers: {Authorization: authToken}});

    //console.log('grid data');
    //console.log(userList.data.data);

    return userList.data.data;
  };

  const exportFilteredData = async () => {

    //console.log('inside export filter Data');

    // console.log('use hook => set isLoading to true')
    // setTimeout(() => {
    //   setIsLoading(true);
    // }, 0);

    

    let isDone = false;
    let curPage = 1;
    let itemsPerPage = 300;
    let rowData = [];

    while (!isDone) {

      const results = await getServerSideDataSource(curPage, itemsPerPage);   

      //console.log('results');
      //console.log(results);

      rowData.push(...results[fetchParams.respKey]);
      const itemsAdded = itemsPerPage * curPage;

      if (itemsAdded >= results.totalRecords) {
        isDone = true;
      } 
      curPage = curPage + 1;
      
      //console.log('current is loading');
      //console.log(isLoading);
      // else {
      //   const remaining = results.totalRecords - itemsAdded;

      // }  
    }
  
    await tempGrid.api.setRowData(rowData);

    tempGrid.api.exportDataAsExcel({ fileName: 'all_filtered_data.xlsx' });
    tempGrid.api.setRowData(null);

    //console.log('use hook => set isLoading to false')
    //setIsLoading(false);
  };

  const setLoading = useCallback((newValue) => {
    //console.log('set loading ' + newValue);

    setIsLoading(newValue);


  }, []);



  const refreshOnSuccess = (result)=> {

    if(result?.meta?.requestStatus === 'fulfilled' || result?.status === 'success') {
      setRefreshTimestamp(Date.now());
    }
  };

  // Thanh 02/04/24
  const refreshGrid = () => {
    setRefreshTimestamp(Date.now());
  }

  return {
    user: user,
    isServiceTitan: company.serviceTitan != undefined,
    userRole: role,
    companyId: companyId,
    isParentCompany: company?.childCompanies?.length > 0,
    grid: grid,
    blocked: blocked,
    fetching: fetching,
    pagination: pagination,
    onFetchFail: onFetchFail,
    createFetchParams: createFetchParams,
    setGridToParent: setGridToParent,
    setTempGridToParent: setTempGridToParent,   // Thanh 05/01/24
    selectedMultipleRows: selectedMultiple,
    setFetching: (s)=>setFetching(s),
    RenderElement: RenderElement,
    setAccessDenied: setAccessDenied,
    getSelected: getSelected,
    exportAll: exportAllAction,
    exportChecked: exportChecked,
    exportFilteredData: exportFilteredData,
    refreshTimestamp: refreshTimestamp,
    refreshOnSuccess: refreshOnSuccess,
    refreshGrid: refreshGrid,
    isLoading: isLoading,
    setIsLoading: (s) =>setIsLoading(s),
    setLoading: setLoading,
  };
};
export {useGridViewState};
