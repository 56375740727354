import React, {useState, useMemo, useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import base_url from '../../../../../baseUrls';
import StatusRenderer, {StatusRenderWithAdditionalData} from '../../../../../pieces/grids/statusRenderer';
import InspectionRequirementsRenderer from '../../../../../pieces/grids/inspectionRequirementsRenderer';
import LineBreakCellRenderer from '../../../../../pieces/grids/lineBreakCellRenderer';
import RequirementFilter from '../../../../../pieces/grids/requirementFilter';
import MyPaginatedGrid from '../../../../../pieces/grids/paginatedGridWrapper';
import {MaximizableCellRenderer} from '../../../../../pieces/grids/MaximizableCellRenderer';
import {getInspectionColumns, inspectionApiTypes} from './inspectionsGridOptions';
import * as allConstants from '../../../../../constants/allConstants';
import {useAutocompleteOptions} from '../../../../../hooks/useAutompleteOptions';
import {useGetFilterList} from '../../../../../hooks/useGetFilterList';
import {useItemSelection} from '../../../../../hooks/useItemSelection';
import {inputTypes} from '../../../../../pieces/inputs/formInput';
import axios from 'axios';
import ApiUtils from '../../../../../utils/apiUtils';
//import {unwrapRequestResult} from '../../../../../utils/unwrapRequestResult';
import {toast} from 'react-toastify'

const AllInspectionsGrid = (props) => {
  const viewController = props.viewController;
  const [components] = useState({
    'statusRenderer': StatusRenderer,
    'statusRenderWithDate': StatusRenderWithAdditionalData,
    'MaximizableCellRenderer': MaximizableCellRenderer,
    'InspectionRequirementsRenderer': InspectionRequirementsRenderer,
    'LineBreakCellRenderer': LineBreakCellRenderer,
    'requirementFilter': RequirementFilter,
    'booleanCellRenderer': (props) => <input type="checkbox" checked={props.value} readOnly />
  });
  

  // useEffect(() => {
  //   (async function getAllTagsAsync () {
  //       const getAllTags = async () => {
  //         tagNames = ['one', 'two', 'three'];
  //       }
  //       await getAllTags();
  //   })();
  // }, [])

  //useEffect( () => {
  //  getAllTags();
  //}, [])
    // Thanh 02/02/24
  //console.log('inside allInspectionsGrid');

  let assigneeData = null;

  const getSchedAssignees = async () => {

    const authToken =  await ApiUtils.getAccessToken();
    const url = `${base_url.api}users/get-schedule-assignees`;
    const newItems = await axios.get(url, {params: {}, headers: {Authorization: authToken}});
  
    //console.log('new Items');
    //console.log(newItems);
  
    return [{ value: 'UNASSIGNED', name: 'UNASSIGNED'}, ...newItems.data.data];
  }

  const submitItemSelection = useCallback(  (selectedText) => {
    //console.log('test callback');
    //console.log(selectedText);

    // const authToken =  await ApiUtils.getAccessToken();
    // const body = { "jobId": jobId, "scheduleAssignee": selectedText};
     
    // const url = `${base_url.api}ipermit/set-schedule-assignee`;
    // await axios.post(url, body, {headers: {Authorization: authToken,}});

  })

  const itemData = {
    inputType: inputTypes.singleSelect,
    title: 'Schedule Assignee',
    defaultValue: '',
    okAction: submitItemSelection,
  }  

  const tagNames = useGetFilterList({url : `${base_url.api}tags/`}).options; 

  const itemSelection = useItemSelection(itemData);


  const jobStatusOptions = useAutocompleteOptions({field: 'jobStatuses'}).options;

  const handleDisplayJobDetails = (params) => {

    // Thanh 01/01/24
    //console.log('handle display job details');
    //console.log(params);

    props.viewJobHandler(params?.node?.data.id);
  };

  const handleAssignInspectionTo = (params) => {

    getSchedAssignees().then (x => {
      assigneeData = x;
      itemSelection.showDialog(assigneeData, params, 'cityInspection,schedAssignee');
    }); 
  }
  const handleUpdateCallHoCount = async (params) => {

    //console.log('inside handle update call ho count');

    const authToken = await ApiUtils.getAccessToken();
    if(!authToken) {
      return;
    }
    //  const url = `${base_url.api}ipermit/jobs/city-inspection/set-schedule-assignee`;
    
    const response = await fetch(
      `${base_url.api}ipermit/jobs/city-inspection/update-call-ho-count`,
      { method: 'POST',
        headers: {Accept: 'application/json','Content-Type': 'application/json', Authorization: authToken,},
        body: JSON.stringify({jobId: params?.node?.data[allConstants.JOB_FIELDS.ID.api_name]}),
      }
    );

    let data = await response.json();

    //console.log('result from update call count');
    // console.log(data);

    if (data.status === 'success') {
      toast.success('Success', {
        toastId: `success_update_call_ho_count`
      });
    }
    else {
      toast.success('Error on updating howwner call count and last call date', {
        toastId: `success_update_call_ho_count`
      });
    }

  
  }

  const columnDefs = useMemo(()=> {

    if (!jobStatusOptions  || !tagNames) {
      return;
    }
    //console.log('inside columndefs - tagnames');
    //console.log(tagNames);

    const columns = getInspectionColumns(handleDisplayJobDetails, 'MaximizableCellRenderer', 
          jobStatusOptions, tagNames?.tags.map ((x) => x.tagName));

    if(props.gridType === inspectionApiTypes.pending) {
      return [
        columns.id,
        columns.jobNumber,
        columns.duplicateOf,
        columns.tags,
        columns.tagsReminderDate,
        columns.schedAssignee,
        columns.inspectionStatusChangeDate, 
        columns.company,
        columns.companyName, 
        columns.jobType,
        columns.assignedCity,
        //columns.needsLadder,
        columns.requirements,
        columns.cityType,
        columns.permitStatus,
        columns.permitStatusChangeDate,
        columns.hersStatus,
        columns.hersStatusChangeDate,
        columns.mailingStatus,
        columns.mailingStatusChangeDate,
        columns.lastHoCallDate,
        columns.callHoCount,
        columns.inspectionScheduledDate,
        columns.inspectionResult,
        columns.inspectionStatus,
      ];
    }
    if(props.gridType === inspectionApiTypes.hold || props.gridType === inspectionApiTypes.completed) {
      return [
        columns.id,
        columns.jobNumber,
        columns.duplicateOf,
        columns.tags,
        columns.tagsReminderDate,
        columns.schedAssignee,
        columns.inspectionStatusChangeDate, 
        columns.company,
        columns.companyName,
        columns.jobType,
        columns.assignedCity,
        //columns.needsLadder,
        columns.requirements,
        columns.permitStatus,
        columns.permitStatusChangeDate,
        columns.hersStatus,
        columns.hersStatusChangeDate,
        columns.mailingStatus,
        columns.mailingStatusChangeDate,
        columns.inspectionScheduledDate,
        columns.inspectionResult,
      ];
    }

    if(props.gridType === inspectionApiTypes.failed) {
      return [
        columns.id,
        columns.jobNumberWithOutResults,
        columns.duplicateOf,
        columns.tags,
        columns.tagsReminderDate,
        columns.schedAssignee,
        columns.inspectionStatusChangeDate,  
        columns.company,
        columns.companyName,
        columns.jobType,
        columns.hoName,
        columns.assignedCity,
        //columns.needsLadder,
        columns.requirements,
        columns.permitStatus,
        columns.permitStatusChangeDate,
        columns.hersStatus,
        columns.hersStatusChangeDate,
        columns.mailingStatus,
        columns.mailingStatusChangeDate,
        columns.lastHoCallDate,
        columns.callHoCount,
        columns.inspectionStatus,
        columns.inspectionScheduledDate,
      ];
    }

    if(props.gridType === inspectionApiTypes.report) {
      return [
        columns.id,
        columns.inspectionStatus,
        columns.jobNumber,
        columns.duplicateOf,
        columns.tags,
        columns.tagsReminderDate,
        columns.schedAssignee,
        columns.inspectionStatusChangeDate, 
        columns.company,
        columns.companyName,
        columns.jobType,
        columns.hoName,
        columns.hoPhone,
        columns.assignedCity,
        //columns.needsLadder,
        columns.requirements,
        columns.cityType,
        columns.permitStatus,
        columns.permitStatusChangeDate,
        columns.hersStatus,
        columns.hersStatusChangeDate,
        columns.mailingStatus,
        columns.mailingStatusChangeDate,
        columns.lastHoCallDate,
        columns.callHoCount,
        columns.inspectionScheduledDate,
        columns.inspectionResult,
      ];
    }

    const head = [
      columns.id,
      columns.jobNumber,
      columns.duplicateOf,
      columns.tags,
      columns.tagsReminderDate,
      columns.schedAssignee,
      columns.inspectionStatusChangeDate,
      columns.company,
      columns.companyName,
      columns.jobType,
      columns.assignedCity,
      //columns.needsLadder,
      
      columns.requirements,
      columns.permitStatus,
      columns.permitStatusChangeDate,
      columns.hersStatus,
      columns.hersStatusChangeDate,
      columns.mailingStatus,
      columns.mailingStatusChangeDate,
      columns.inspectionStatus,
      columns.lastHoCallDate,
      columns.callHoCount,
      columns.inspectionScheduledDate,
      columns.inspectionResult,
    ];

    if(props.gridType === inspectionApiTypes.manual) {
      //insert the manual inspection column at 3 position
      head.splice(3, 0, columns.createdTime);
    }

    return head;


  }, [jobStatusOptions, tagNames]);


  const getFetchParams = (gridType)=> {
    const baseJobsUrl = `${base_url.api}jobs`;
    const ipermitBaseJobsUrl = `${base_url.api}ipermit/jobs`;
    const respKey1 = 'inspections';

    //const baseJobsUrl = `${base_url.api}jobs/`;
    let url = baseJobsUrl;
    let params = {};
    if(gridType === inspectionApiTypes.previous) {
      url = `${ipermitBaseJobsUrl}/city-inspection/get-previous-inspections`;
    } else if(gridType === inspectionApiTypes.pending) {
      url = `${ipermitBaseJobsUrl}/city-inspection/get-inspections-with-status`;
      //console.log('pending', url);
      params.status = allConstants.INSPECTION_STATUSES.PENDING.value;
    } else if(gridType === inspectionApiTypes.hold) {
      url = `${ipermitBaseJobsUrl}/city-inspection/get-inspections-with-status`;
      params.status = allConstants.INSPECTION_STATUSES.HOLD.value;
    }
    else if(gridType === inspectionApiTypes.completed) {
      url = `${ipermitBaseJobsUrl}/city-inspection/get-inspections-with-status`;
      params.status = allConstants.INSPECTION_STATUSES.COMPLETED.value;
    }
    else if(gridType === inspectionApiTypes.manual) {
      url = `${ipermitBaseJobsUrl}/city-inspection/get-manual-inspections`;
    }
    else if(gridType === inspectionApiTypes.failed) {
      url = `${url}/get-failed-inspections`;
    }
    else {
      url = `${url}/get-inspection-jobs`;
    }

    //console.log('inspection ur l =>' +  url)
    
    return {url: url, respKey: respKey1, options: params};
  };

  const getContextMenuItems = (params)=> {

    let standard = [
      {
        name: 'Assign Inspection To',
        cssClasses: ['greenFont', 'bold'],
        disabled: false,
        action: () => {handleAssignInspectionTo(params);},
      },
      {
        name:  'Show Job Details',
        cssClasses: ['blueFont', 'bold'],
        disabled: viewController.selectedMultipleRows(),
        action: () => {handleDisplayJobDetails(params);},
      },
      'separator',
      {
        name: 'Update Homeowner Call Count and Last Call Date',
        cssClasses: ['blueFont', 'bold'],
        disabled: false,
        action:  () => {handleUpdateCallHoCount(params);},
      },
      'separator',
      {
        name:  'Export Selected (.xlsx)',
        action: () => params.api.exportDataAsExcel(
          {onlySelected: true}
        )
      },
      'copy',
    ];

    let listOfActions = [...standard];
    return listOfActions;

  };

  const fetchParams = getFetchParams(props.gridType);

  if(!jobStatusOptions || !tagNames) return null;
  return (
    <>
      {itemSelection.mainDialog()}

      <MyPaginatedGrid columnDefs={columnDefs}
        components={components}
        ls_name={'all_inspections'}
        contextMenu={getContextMenuItems}
        onCellDoubleClicked={handleDisplayJobDetails}
        registerGrid={viewController.setGridToParent}
        registerTempGrid={viewController.setTempGridToParent}    // Thanh 05/01/24
        fetchParams={fetchParams}s
        pagination={props.pagination}
        onFetchFail={viewController.onFetchFail}
        defFilterParams={props.defFilterParams}
        additionalFilters={props.additionalFilters}
        //fieldsToFilterOnEnter={[allConstants.JOB_FIELDS.JOB_NUMBER]}
      />
    </>
  );
};

AllInspectionsGrid.propTypes = {
  pagination: PropTypes.instanceOf(Object).isRequired,
  gridType: PropTypes.oneOf(['hers', 'permits', 'all', 'previous', 'pending', 'manual', 'completed', 'hold', 'failed', 'report']),
  viewController: PropTypes.instanceOf(Object).isRequired,
  defFilterParams: PropTypes.instanceOf(Array),
  viewJobHandler: PropTypes.func.isRequired,
  additionalFilters: PropTypes.instanceOf(Object),
};

AllInspectionsGrid.defaultProps = {
  gridType: 'pending',
  defFilterParams: [],
  additionalFilters: {},
};
export default AllInspectionsGrid;
