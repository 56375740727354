import React, {useEffect, useState, useRef} from 'react';
import {useFormContext, useFieldArray} from 'react-hook-form';
import {companyPropTypes, companyDefaultProps} from './panelProps';
import CustomFormBlock from '../../../pieces/inputs/formBlock';
import * as allConstants from '../../../constants/allConstants';
import Grid from '@mui/material/Grid';
import {Box, Card, CardContent, List, Typography, ListItem, FormControl} from '@mui/material';
import {useCompanyPageStyles} from '../companyPageStyles';
import {globalUseClasses} from '../../../styles/globalClasses';
import {inputTypes} from '../../../pieces/inputs/formInput';
import Utils from '../../../utils/utils';
import base_url from '../../../baseUrls';
import {NotAllowedToEditSection} from './notAllowedToEditSection';
import {AddRowButton, DeleteEquipmentRowButton, DeleteRowButton, variants} from '../../../pieces/addDeleteEquipmentRowButtons';
import { AutoCompleteComponent} from '@syncfusion/ej2-react-dropdowns';
import { DataManager, Query, UrlAdaptor } from '@syncfusion/ej2-data';
import {FloatingLabel} from '../../../pieces/inputs/customInputComponent';
import ApiUtils from '../../../utils/apiUtils';
import './companySettings.css';


class CustomUrlAdaptor extends UrlAdaptor {

  // Override the processResponse method
  processResponse(data, success) {

      //console.log("return data => ", data);
      if (success) {
         return data.data;
      } else {
         // Handle errors or failed requests
         console.error('Request failed:', data);
         return [];
      }
  }
}
export const CompanySettings = (props)=> {
  const [init] = useState(true);
  const [companyDataMgr, setCompanyDataMgr] = useState([]);

  const [parentCompanyName, setParentCompanyName] = useState();
  const classes = {...useCompanyPageStyles(), ...globalUseClasses()};
  const COMPANY_FIELDS = allConstants.COMPANY_FIELDS;
  const CHILD_COMPANY_FIELDS = allConstants.CHILD_COMPANY_FIELDS;

  let authToken = useRef(null);
  const queryCompany = new Query().take(30).skip(0);

  const formMethods = useFormContext();

  const getCompanyData = ()=> {
    const initialData = props.tabProps?.companyData || {};
    return {...initialData, ...formMethods.getValues()};
  };

  const companyData = getCompanyData();

  //console.log('company data');
  //console.log(companyData);


  useEffect ( async () => {

    authToken.current = await ApiUtils.getAccessToken();
    const urlCompany = `${base_url.api}companies/autocomplete`;
    setParentCompanyName(companyData?.parentCompany?.companyName);

    const compDataMgr = new DataManager({
        url: urlCompany,
        adaptor: new CustomUrlAdaptor(),
        crossDomain: true,
        offline: false,
        headers: [ {'Authorization': authToken.current}],
        onFailure: (e) => {
            console.error('API call failed', e);
        }
    });
    setCompanyDataMgr(compDataMgr);

  }, [init]);

  const getDefaultValue = (field, parent = null) => {
    if (parent) {
      return companyData?.[parent]?.[field];
    }
    return companyData?.[field];
  };

  const commonFieldProps = {
    fullWidth: true,
    maxWidth: '100%'
  };

  const companyCollectionName = COMPANY_FIELDS.CHILD_COMPANIES.api_name;

  const companyRow = {
    [CHILD_COMPANY_FIELDS.COMPANY_ID.api_name]: null,
    [CHILD_COMPANY_FIELDS.COMPANY_NAME.api_name]: '',
  };

  const {fields, append} = useFieldArray({
    control: formMethods.control,
    name: companyCollectionName,
  });

  const companySubformDefaultProps = (index) => ({
    defaultValue: '',
    isEditable: true,
    nested: true,
    fullWidth: true,
    parent: `${companyCollectionName}[${index}]`
  });

  const deleteCompanyRow = (index) => {
    try {
      const childCompanies = [...formMethods.getValues()?.[allConstants.COMPANY_FIELDS.CHILD_COMPANIES.api_name]];
      childCompanies.splice(index, 1);
      formMethods.setValue(allConstants.COMPANY_FIELDS.CHILD_COMPANIES.api_name, childCompanies);
    } catch(e){

    }
  };

  const onChangeCompany = (args) => {

    //console.log('on change company');
    //console.log(args.itemData);

    if (args.itemData) {       // setSelectedCompanyId(args.itemData.companyId);   
      formMethods.setValue(`${[COMPANY_FIELDS.PARENT_COMPANY.api_name]}.${[CHILD_COMPANY_FIELDS.COMPANY_ID.api_name]}`, args.itemData.companyId);     
      formMethods.setValue(`${[COMPANY_FIELDS.PARENT_COMPANY.api_name]}.${[CHILD_COMPANY_FIELDS.COMPANY_NAME.api_name]}`, args.itemData.companyName);     
      setParentCompanyName(args.itemData.companyName);
    }
    else {
      formMethods.setValue(`${[COMPANY_FIELDS.PARENT_COMPANY.api_name]}.${[CHILD_COMPANY_FIELDS.COMPANY_ID.api_name]}`, '');     
      formMethods.setValue(`${[COMPANY_FIELDS.PARENT_COMPANY.api_name]}.${[CHILD_COMPANY_FIELDS.COMPANY_NAME.api_name]}`, '');     
    }
  };

  return (
    <>
      <style>

      </style>
      <Grid container maxWidth={'100%'} spacing={3}>
        <NotAllowedToEditSection show={props.tabProps.notAllowedToEdit}/>
        <Grid item sm={12} md={6}>
          <Card className={classes.card_block}>
            <CardContent className={classes.half_width}>
              <CustomFormBlock
                {...props.inputDefaultProps}
                {...commonFieldProps}
                field={COMPANY_FIELDS.NAME}
                defaultValue={getDefaultValue(COMPANY_FIELDS.NAME.api_name)}
              />
              <CustomFormBlock
                {...props.inputDefaultProps}
                {...commonFieldProps}
                field={COMPANY_FIELDS.EMAIL}
                defaultValue={getDefaultValue(COMPANY_FIELDS.EMAIL.api_name)}
              />
              {props.tabProps.isIpermitInvite && <CustomFormBlock
                {...props.inputDefaultProps}
                field={COMPANY_FIELDS.STATE_LICENSE_NUMBER}
                nested={true}
                parent={COMPANY_FIELDS.STATE_LICENSE.api_name}
                fullWidth={true}
                defaultValue={getDefaultValue(COMPANY_FIELDS.STATE_LICENSE_NUMBER.api_name, COMPANY_FIELDS.STATE_LICENSE.api_name)}
              />
              }
              <Box className={classes.two_col_container} sx={{
                '& .MuiSelect-multiple': {
                  minWidth: 'unset !important'
                }
              }}>
                <CustomFormBlock
                  {...props.inputDefaultProps}
                  {...commonFieldProps}
                  field={COMPANY_FIELDS.LOCATION}
                  inputType={inputTypes.multiSelect}
                  options={Utils.getOptions(props?.tabProps?.options?.markets)}
                  defaultValue={getDefaultValue(COMPANY_FIELDS.LOCATION.api_name)}
                />
                <CustomFormBlock key={JSON.stringify(getDefaultValue(COMPANY_FIELDS.ALLOWED_TESTS.api_name))}
                  {...props.inputDefaultProps}
                  {...commonFieldProps}
                  field={COMPANY_FIELDS.ALLOWED_TESTS}
                  inputType={inputTypes.multiSelect}
                  options={Utils.getOptions(allConstants.TEST_TYPES)}
                  defaultValue={getDefaultValue(COMPANY_FIELDS.ALLOWED_TESTS.api_name)}
                />
              </Box>
              <CustomFormBlock
                {...props.inputDefaultProps}
                {...commonFieldProps}
                field={COMPANY_FIELDS.ALLOWED_PERMITS}
                inputType={inputTypes.multiSelect}
                options={Utils.getOptions(allConstants.JOB_TYPES)}
                width={'100%'}
                defaultValue={getDefaultValue(COMPANY_FIELDS.ALLOWED_PERMITS.api_name)}
              />
              <div className="box-container">
                <label className="box-label">{COMPANY_FIELDS.PARENT_COMPANY.label}</label>
                <Box sx={{
                    border: '2px solid lightgrey',  
                    padding: '4px',
                    borderRadius: '4px',
                    width: '100%',
                    position: 'relavtive',
                  }} >
                  {companyDataMgr && 
                  <AutoCompleteComponent id={COMPANY_FIELDS.PARENT_COMPANY.label}  sortOrder="Ascending"
                        dataSource={companyDataMgr} query={queryCompany} fields={{ value: 'companyName', text: 'companyName'}} 
                        enabled={!props.inputDefaultProps.viewMode}                                              
                        value={parentCompanyName} minLength={3} filterType='Contains' change={onChangeCompany}
                        style={{ borderRadius: '8px',  width: '450px', marginRight: '15px',  paddingLeft: '4px' }}/>    
                  }              
                </Box>
              </div>
              <CustomFormBlock
                 {...props.inputDefaultProps}
                 {...commonFieldProps}
                 parent={COMPANY_FIELDS.PARENT_COMPANY.api_name}
                 field={CHILD_COMPANY_FIELDS.COMPANY_ID}
                 defaultValue={getDefaultValue(CHILD_COMPANY_FIELDS.COMPANY_ID.api_name, COMPANY_FIELDS.PARENT_COMPANY.api_name)}
                 hide={true}
              /> 
            </CardContent>
          </Card>
        </Grid>
        <Grid item sm={12} md={6} flex={1}  >
          <Card className={classes.card_block}>
            <CardContent>
              <Box mb={2}>
                <Typography className={classes.eq_name}>{COMPANY_FIELDS.CHILD_COMPANIES.label}</Typography>
              </Box>
              {/* <List>
                <ListItem sx={{
                  display: 'grid',
                  gridTemplateColumns: '2fr 1fr',
                  gap: '16px',
                  alignItems: 'flex-start',
                  textTransform: 'uppercase'
                }}>
                  <Typography>Company Name</Typography>
                </ListItem>
              </List> */}
              <form>
                <List>
                  {fields.map((item, index) => (
                    <ListItem key={item.id} sx={{
                      display: 'grid',
                      gridTemplateColumns: '2fr 0.5fr',
                      gap: '16px',
                      alignItems: 'flex-start'
                    }}>
                      <CustomFormBlock
                        {...props.inputDefaultProps}
                        {...commonFieldProps}
                        {...companySubformDefaultProps(index)}
                        field={{...CHILD_COMPANY_FIELDS.COMPANY_NAME, ...{required: false}}}
                        inputType={inputTypes.autoComplete}
                        autocompleteParams={{
                          url: `${base_url.api}childCompanies/autocomplete`,
                          getRequestParams: (value)=> {
                            if(value) {
                              return {
                                name: value
                              };
                            }
                          },
                          collectionName: companyCollectionName,
                          fill: [CHILD_COMPANY_FIELDS.COMPANY_ID],
                          filter: (options)=> {
                            const existingRows = formMethods.getValues()?.[allConstants.COMPANY_FIELDS.CHILD_COMPANIES.api_name];
                            if(!existingRows) return options;
                            const existingProducts = existingRows.map((row)=> row[CHILD_COMPANY_FIELDS.COMPANY_NAME.api_name]);
                            return options.filter((option)=> !existingProducts.includes(option[CHILD_COMPANY_FIELDS.COMPANY_NAME.api_name]));
                          }
                        }
                        }
                      />
                      <CustomFormBlock
                        {...props.inputDefaultProps}
                        {...commonFieldProps}
                        {...companySubformDefaultProps(index)}
                        field={CHILD_COMPANY_FIELDS.COMPANY_ID}
                        hide={true}

                      />
                      {/* {!props.inputDefaultProps.viewMode && <DeleteRowButton eqName={'childCompanies'} index={index} 
                        extraStyles={{alignSelf: 'baseline',
                          top: '8px',
                          position: 'relative',
                          padding: '0'
                        }}
                        deleteAction={() => deleteCompanyRow(index)}
                      />} */}
                    </ListItem>
                  ))}
                </List>
                {/* {!props.inputDefaultProps.viewMode && props.inputDefaultProps.isEditable &&
                <AddRowButton eqName={'childCompanies'} variant={variants.textWithIcon}
                  addAction={() => {
                    append({...companyRow});
                  }}
                />} */}
              </form>

            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

CompanySettings.propTypes = companyPropTypes;
CompanySettings.defaultProps = companyDefaultProps;
