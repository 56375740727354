import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {Box, Grid, Paper} from '@mui/material';
import * as STYLE_CONSTANTS from '../../styles/styleConstants';
import {globalUseClasses} from '../../styles/globalClasses';



const DisplayListsPageWrapper = (props)=> {
  const classes = {...globalUseClasses()};
  return(
    <Box className={clsx(classes.content_container, classes.grid_view)} style={props.containerStyle} >
      <Paper elevation={0} sx={{height: '100%'}}>
        <Grid container sx={{height: '100%'}}>
          <Grid item xs={12} pt={1} style={{borderBlockEnd: `2px solid ${STYLE_CONSTANTS.COLORS.lightGray}`}}>
            <Box style={{alignItems: 'center', display:'flex', justifyContent: 'space-between',
              width: '96%', margin: 'auto', height: '40px'}}>
              {props.headerComponent}
            </Box>

          </Grid>
          <Grid item xs={12} style={{width: '100%', paddingTop: '12px'}} display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'center'}>
            {props.children}
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );

};

export default DisplayListsPageWrapper;

DisplayListsPageWrapper.propTypes = {
  headerComponent: PropTypes.element
};

DisplayListsPageWrapper.defaultProps = {
  headerComponent: <></>
};
